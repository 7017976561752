import React from 'react';
import logo from './logo.jpg';
import './App.css';
import ReactHtmlParser from 'react-html-parser';
import fileJson from "./JSON/events.json";

function addHours(date, h) {
  var date2 = new Date(date + (h*60*60*1000));
  return date2;
}

function addDays(prevDate, days) {
  let ticksCount = prevDate + (days * 24*60*60*1000);
  var newDate = new Date(ticksCount);
  var prevHours = new Date(prevDate).getHours();

  if (prevHours !== newDate.getHours())
  {
    newDate = new Date(newDate.setHours(prevHours));
  }
  return newDate;
}

function DateAddDaySuffix(date)
{
    var suffix = 'th';
    switch (date)
    {
      case 1: case 21: case 31: suffix = 'st'; break;
      case 2: case 22: suffix = 'nd'; break;
      case 3: case 23: suffix = 'rd'; break;
      default: break;
    }
    return date + suffix;
}

function get12HrTime(datetime)
{
    var hours = datetime.getHours();
    var hours12 = hours - (hours > 12 ? 12 : 0);
    return (hours12 < 10 ? hours12 : hours12)
        + '.'
        + datetime.getMinutes()
        //+ (hours > 12 ? 'pm' : 'am')
        ;
}

function getDatePrefix(datetime)
{
    return DateAddDaySuffix(datetime.getDate())
        + ' '
        + new Intl.DateTimeFormat('en-US', { month: 'long'}).format(datetime)
        + ' '
        + get12HrTime(datetime)
        + '';
}

function getDateText(datetime, addTimeOfDay)
{
    var hours = datetime.getHours();
    var result = getDatePrefix(datetime)
      + (addTimeOfDay !== 0 
          ? (hours < 12 ? 'Morning' : (hours < 18 ? 'Afternoon' : 'Evening'))
              + ' ' 
          : '');

    return result;
}


function App() {
  let offSet = 0; 
  let counter = 0;
  let output = "";
  const hrsAfterEventToRemove = 2;
  for (var i in fileJson)
  {
    if (counter >= 3) break;

    var item = fileJson[i];
    offSet += item.OffSet;
    let currentDate = Date.parse(item.StartDateTime);

    if (item.DateTime != null)
    {
      currentDate = Date.parse(addDays(currentDate, offSet * 7));
    }

    if (item.DateTime === null || addHours(currentDate, hrsAfterEventToRemove) > new Date())
    {
      output += "<b>" + item.Title + "</b><br />" + getDateText(new Date(currentDate), 0) + " in the " + item.Location + ".<br/><br/>";
      counter ++;
    }
  }
      
  return (
    <div className="App">
      <header className="App-header">
        
        <div className="col-12">
          <div className="col-12">
            <h1><strong>Discussions to discover the meaning of Life</strong></h1>
            <h2 style={{ color: "Red" }}>Postponed Indefinitely</h2>
          </div>
          <div 
            className="col-12 col-sm-2 offset-sm-5 justify-content-sm-center" 
            style={{
              //height: logo.height,
              //minHeight: "100%"
            }}>
            <img src={logo} className="-App-logo fitImage pull-xs-left" alt="logo" />
          </div>
          <div className="col-12">
            <h2><strong>Upcoming SHL based events:</strong></h2>
            <p>
            { ReactHtmlParser(output) }
            </p>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;